<template>
  <v-layout wrap>
    <v-flex xs12 pa-2 style="color: black; font-size: 25px" class="text"
      >Detailed View</v-flex
    >

    <v-card contain width="200%">
      <v-flex xs12 pl-1>
        <v-layout wrap>
          <v-flex xs3 pa-3>
            <span class="text"
              ><strong>First Name : &nbsp;</strong>{{ list.firstName
              }}<br /></span
          ></v-flex>
          <v-flex xs3 pa-3>
            <span class="text"
              ><strong>Last Name : &nbsp;</strong>{{ list.lastName
              }}<br /></span
          ></v-flex>
          <v-flex xs3 pa-3 class="text">
            <span><strong>Email : &nbsp;</strong>{{ list.email }}<br /></span>
          </v-flex>
          <v-flex xs3 class="text" pt-3>
            <span>
              <strong>Outlet Associations : &nbsp;</strong>

              {{ list.media }}<br
            /></span>
          </v-flex>
          <v-flex class="text" xs3 pa-3>
            <span>
              <strong>Job Title : &nbsp;</strong> {{ list.jobTitle
              }}<br /></span
          ></v-flex>
          <v-flex pa-3 xs3 class="text">
            <span>
              <strong>Reason of access : &nbsp;</strong>
              {{ list.reasonForAccess }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>WTI contact : &nbsp;</strong> {{ list.knownWTIContact
              }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pt-3 pr-2 class="text">
            <span>
              <strong>Country : &nbsp;</strong> {{ list.country }}<br
            /></span>
          </v-flex>
          <v-flex pa-3 xs3 class="text">
            <span>
              <strong>Address 1 : &nbsp;</strong> {{ list.address1 }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>Address 2 : &nbsp;</strong> {{ list.address2 }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span> <strong>City : &nbsp;</strong> {{ list.city }}<br /></span>
          </v-flex>
          <v-flex xs3 pt-3 pr-2 class="text">
            <span>
              <strong>State/Province : &nbsp;</strong> {{ list.state }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>Zip / Postal Code : &nbsp;</strong> {{ list.pinCode
              }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>Facebook : &nbsp;</strong>{{ list.faceBookURL }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>Instagram : &nbsp;</strong> {{ list.instagramURL }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pt-3 class="text" pr-2>
            <span>
              <strong>Twitter : &nbsp;</strong> {{ list.twitterURL }}<br
            /></span>
          </v-flex>
          <v-flex xs3 pa-3 class="text">
            <span>
              <strong>Phone Number : &nbsp;</strong> {{ list.phone }}<br
            /></span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card>
  </v-layout>
</template>


<script>
import axios from "axios";
export default {
  data() {
    return {
      id: this.$route.query.id,
      list: {},
    };
  },

  mounted() {
    this.getlist();
  },

  methods: {
    getlist() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/user/view",
        data: {
          id: this.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
